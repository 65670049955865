.wrapper {
  width: 100%;
  min-height: 100%; 
  display: flex;
  flex-direction: column;
  background-color: var(--chakra-colors-whiteAlpha-900);
  border-radius: 0 var(--chakra-radii-xl) var(--chakra-radii-xl) 0;
}

.navItem { 
  text-decoration: none;
}

.selected {
  color: var(--chakra-colors-quredBlue-600);
}

.selected:hover  {
  color: var(--chakra-colors-quredBlue-600);
}
