body, html {
  width: 100%;
  height: 100%;
  background-color: var(--chakra-colors-quredGray-600);
}

:root {
  --header-height: 48px;
  --sidebar-width: 216px;
  --footer-height: 64px;
  --timeline-section-length: 290px;
  --z-index-timeline-appointment-card: 1;
}

.str-video {
  background-color: #272a30;
  color: #ffffff;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}